import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import checkmarkCircle from '../../images/checkmark-circle-green.png';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';
import sendRequest from '../../utils/forms/API/sendRequest';
import { getData } from '../../utils/utilityFunctions';

const fadeIn = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  },
  100% {
    transform: scale('100');
    opacity: 1;
  }
`;

const ThankyouWrapper = styled.div`
  margin: 0;

  li {
    background: url(${checkmarkCircle}) no-repeat 0px 0px/20px transparent;
    list-style-type: none;
    margin: 0;
    padding: 0px 0px 10px 24px;
    vertical-align: middle;
  }
`;

const Intro = styled.section`
  min-height: 100px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 0;
  min-height: 120px;
  animation: ${fadeIn} 0.9s;
  @media (max-width: 801px) {
    flex-direction: column;
    padding: 0 20px;
  }
  p {
    font-size: clamp(24px, 3.5vw, 44px);
    text-align: center;
    color: #017a4e;
    font-weight: 700;
    margin: 0 10px;
    @media (max-width: 801px) {
      margin: 10px 0;
    }
  }
  button {
    color: #fff;
    background: #017a4e;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-weight: 700;
    border: none;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 801px) {
      padding: 10px;
    }
  }
`;

const Header = styled.header`
  background: #ebebeb;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    font-size: clamp(20px, 3.5vw, 35px);
    color: #333;
    text-align: center;
    margin: 0;
    font-weight: 600;
  }
`;

const Content = styled.section``;

const MidContent = styled.div`
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 264px;
  }

  div {
    width: 70%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding-left: 40px;

  > div {
    width: 45%;
  }

  p,
  h4,
  img {
  }

  @media (max-width: 900px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
  }
`;

const BottomSub = styled.div`
  display: flex;
  img {
    width: 150px;
    height: 150px;
    margin-right: 15px;
  }
  div {
    width: 95%;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Text = styled.p`
  span {
    font-weight: 700;
  }

  @media (max-width: 801px) {
    font-size: 14px;
  }
  .link {
    font-style: italic;
    color: #017a4e;
    font-weight: 700;
  }
`;

const EbmThankyou = () => {
  const [redirectLink, setRedirectLink] = useState('');
  const [clickPix, setClickPix] = useState('');

  const trackConversion = trackingUrl => {
    if (trackingUrl && trackingUrl !== '[TRACKING_LINK]') {
      const trackingPixel = document.createElement('img');
      trackingPixel.src = trackingUrl;
      trackingPixel.width = 1;
      trackingPixel.height = 1;
      trackingPixel.style.display = 'none';
      document.body.appendChild(trackingPixel);
    }
  };

  const openUrl = (url, trackingUrl) => {
    trackConversion(trackingUrl);
    window.open(url, '_blank');
  };

  const translatedPurchaseTimeline = () => {
    const data = getData('moving_date');

    switch (data) {
      case 'Right Away':
        return 'zero-to-three-months';
      case 'Within 90 Days':
        return 'zero-to-three-months';
      case '3-6 Months':
        return 'three-to-six-months';
      case '6-12 Months':
        return 'over-six-months';
      case '12+ Months':
        return 'not-sure';
      default:
        return '';
    }
  };

  const translatedWantsCashOut = () => {
    const data = getData('additional_cash_needed');

    switch (data) {
      case '5000':
        return true;
      case '0':
        return false;
      default:
        return '';
    }
  };

  const translatedOwnCurrentHome = () => {
    const data = getData('loan_purpose');

    switch (data) {
      case 'Purchase':
        return false;
      case 'Refinance':
        return true;
      default:
        return '';
    }
  };

  const translatedPropertyPrice = () => {
    const loanPurpose = getData('loan_purpose');
    let propertyPrice;
    if (loanPurpose === 'Purchase') {
      propertyPrice = getData('est_purchase_price');
    } else {
      propertyPrice = getData('est_property_value');
    }
    return propertyPrice;
  };

  const translatedHasRealtor = () => {
    const data = getData('agent_found');

    switch (data) {
      case '0':
        return false;
      case '1':
        return true;
      default:
        return '';
    }
  };

  const translatedPropertyType = () => {
    const data = getData('property_type');

    switch (data) {
      case 'Single Family':
        return 'SingleFamilyHome';
      case 'Townhouse':
        return 'TownHouse';
      case 'Mobile Home':
        return 'MobileOrManufactured';
      case 'Manufactured':
        return 'MobileOrManufactured';
      case 'SingleFamily':
        return 'SingleFamilyHome';
      default:
        return '';
    }
  };

  const translatedDownPayment = () => {
    const percentDown = getData('estimated_down_payment');
    const costOfHouse = getData('est_purchase_price');
    const loanPurpose = getData('loan_purpose');

    let downPayment;
    const updateDecimal = () => {
      switch (+percentDown) {
        case 5:
          return 0.05;
        case 10:
          return 0.1;
        case 15:
          return 0.15;
        case 20:
          return 0.2;
        case 25:
          return 0.25;
        default:
          return '';
      }
    };

    if (loanPurpose === 'Purchase') {
      if (percentDown && costOfHouse) {
        downPayment = +costOfHouse * updateDecimal();
      }
    } else {
      downPayment = '';
    }
    return downPayment;
  };

  const translatedLoanAmount = () => {
    const loanPurpose = getData('loan_purpose');

    let loanAmount;

    if (loanPurpose === 'Purchase') {
      loanAmount = +getData('est_purchase_price');
    } else {
      loanAmount = +getData('est_property_value');
    }
    return loanAmount;
  };

  const translatedSubjectPropertyZipCode = () => {
    const loanPurpose = getData('loan_purpose');
    let zipCode;

    if (loanPurpose === 'Purchase') {
      zipCode = getData('property_zip');
    } else {
      zipCode = getData('zipcode');
    }
    return zipCode;
  };

  const translatedSubjectPropertyState = () => {
    const loanPurpose = getData('loan_purpose');
    let state;

    if (loanPurpose === 'Purchase') {
      state = getData('property_state');
    } else {
      state = getData('state');
    }
    return state;
  };

  const translatedSubjectPropertyCity = () => {
    const loanPurpose = getData('loan_purpose');
    let city;

    if (loanPurpose === 'Purchase') {
      city = getData('property_city');
    } else {
      city = getData('city');
    }
    return city;
  };

  const translatedSubjectPropertyAddress = () => {
    const loanPurpose = getData('loan_purpose');
    let address;

    if (loanPurpose === 'Purchase') {
      address = '';
    } else {
      address = getData('address');
    }
    return address;
  };

  const translatedCreditRating = () => {
    const credit = getData('credit_rating');

    switch (credit) {
      case 'excellent':
        return 'Excellent';
      case 'good':
      case 'average':
        return 'Good';
      case 'fair':
        return 'Fair';
      case 'poor':
        return 'Poor';
      default:
        return '';
    }
  };

  useEffect(() => {
    const requestData = {
      sourceName: 'ratemarketplace',
      loanPurpose: getData('loan_purpose'),
      borrowerEmail: getData('email'),
      affiliateId: getData('affiliate_id') || '52',
      purchaseTimeline: translatedPurchaseTimeline(),
      borrowerFirstName: getData('first_name'),
      borrowerLastName: getData('last_name'),
      borrowerPhone: getData('phone_primary'),
      borrowerStreetAddress: getData('address'),
      borrowerCity: getData('city'),
      borrowerState: getData('state'),
      borrowerZipCode: getData('zipcode'),
      loanAmount: translatedLoanAmount(),
      wantsCashOut: translatedWantsCashOut(),
      ownCurrentHome: translatedOwnCurrentHome(),
      propertyPrice: +translatedPropertyPrice(),
      hasRealtor: translatedHasRealtor(),
      subjectPropertyType: translatedPropertyType(),
      subjectPropertyDownPayment: translatedDownPayment(),
      subjectPropertyStreetAddress: translatedSubjectPropertyAddress(),
      subjectPropertyZipCode: translatedSubjectPropertyZipCode(),
      subjectPropertyState: translatedSubjectPropertyState(),
      property_city: translatedSubjectPropertyCity(),
      creditScoreRange: translatedCreditRating(),
    };

    const url = 'https://external.printfinger.tech:7106/api/BetterMortgage';

    const successFunction = res => {
      try {
        if (res && res.statusCode === 200) {
          setRedirectLink(res.preapprovalUrl);
          setClickPix(
            `https://trking2.com/?f5c=rS4hLbZHpCCyldSm5%2fQhvjHyoULgxPWH&s1=${getData(
              'loan_purpose'
            )}`
          );
        } else {
          setRedirectLink(
            'https://welcome.better.com/rate-market-place/?utm_source=ratemarketplacelp&utm_campaign=mixed&utm_content=#affid#_#partner#&utm_term=#reqid#'
          );
          setClickPix(
            `https://trkthat4.com/?oex3=xdR6%2fNAqAYyk033R62PBszG7Dj%2frHPqx&s1=${getData(
              'loan_purpose'
            )}`
          );
        }
      } catch (error) {
        setRedirectLink(
          'https://welcome.better.com/rate-market-place/?utm_source=ratemarketplacelp&utm_campaign=mixed&utm_content=#affid#_#partner#&utm_term=#reqid#'
        );
        setClickPix(
          `https://trkthat4.com/?oex3=xdR6%2fNAqAYyk033R62PBszG7Dj%2frHPqx&s1=${getData(
            'loan_purpose'
          )}`
        );
      }
    };

    sendRequest(requestData, url, successFunction, 'POST');
  }, []);

  return (
    <ThankyouWrapper>
      {redirectLink && (
        <Intro>
          <p>Get Your Personalized Rates</p>
          <p>
            <button
              type="button"
              onClick={() => openUrl(redirectLink, clickPix)}
            >
              Click Here
            </button>
          </p>
        </Intro>
      )}
      <Header>
        <h1>Congratulations! We've Found A Match!</h1>
      </Header>
      <Content>
        <MidContent>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/better-logo.png"
            alt="Lendilio"
          />
          <div>
            <Text>
              Better Mortgage doesn't charge lender fees, commission, or any
              hidden fees. That means rates stay competitive even when market
              rates change. See for yourself! It takes as little as 3 minutes to
              get pre-approved and see your rates.
            </Text>
          </div>
        </MidContent>
        <hr />
        <BottomContent>
          <div>
            <h4>
              Better focuses on support, not sales. So whether you're here for
              your first house or third refinances, Better is ready to help.
              Better dismantled the old mortgage infrastructure and replaced it
              with innovative technology and far fewer hurdles.
            </h4>
            <ul>
              <li>Pre-approval takes as little as 3 minutes</li>
              <li>Award-winning customer support</li>
              <li>Close up to 10 days faster than the industry average</li>
              <li>Instant loan estimates, 24/7 on-demand rate lock</li>
              <li>More than $42Bn in loans funded since inception</li>
            </ul>
          </div>

          <div>
            <h4>I was very skeptical of an online mortgage broker...</h4>
            <BottomSub>
              <img
                src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/BetterHouse.png"
                alt="testimonial"
              />
              <div>
                <Text>
                  Wow - I was super impressed with the low rates, simplicity of
                  the process, and speed of the overall transaction. It was easy
                  to navigate and use the online process to complete tasks, but
                  when I had a question it was easy to reach someone by email or
                  phone. I also loved that services and help are available in
                  the evenings and weekends (unlike many backs and other
                  brokers). I had a great experience with Better and have
                  already recommended the company to two friends.
                </Text>
                <Text>
                  <a
                    className="link"
                    href="https://www.trustpilot.com/review/better.com"
                  >
                    https://www.trustpilot.com/review/better.com
                  </a>
                </Text>
              </div>
            </BottomSub>
          </div>
        </BottomContent>
        <p style={{ fontSize: '12px', textAlign: 'center' }}>
          Better Mortgage NMLS #330511
        </p>
        <hr />
      </Content>
    </ThankyouWrapper>
  );
};

export default withThankyouWrapper(EbmThankyou);
